<template>

    <b-form-row class="m-1">
      <b-col cols=12 sm="6" class="my-1">
        <label class="sr-only" for="inline-form-input-search">{{ t('search') }}</label>
        <b-form-input id="inline-form-input-search" v-model="query" :placeholder="t('search')" @keyup="setQuery"></b-form-input>
      </b-col>
      <b-col cols=12 sm="6" class="my-1">
        <InputFolder @select="setCurrentFolderId($event)" :initialId="currentFolderId" />
      </b-col>
    </b-form-row>

</template>

<script>

import InputFolder from '@/components/InputFolder.vue'

export default {
  components: {
    InputFolder
  },
  data() {
    return {
      currentFolderId: this.$store.state.currentFolderId,
    }
  },
  computed: {
    query: {
      get: function() {
        return this.$store.state.query
      },
      set: function(text) {
        this.$store.commit('setQuery', text);
      },
    },

  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    setQuery: function(e) {
      var query = (e.key == 'Escape') ? "" : e.target.value
      this.query = query
    },
    resetQuery: function() {
      this.query = ""
    },
    setCurrentFolderId: function(folder_id) {
      this.$store.commit('setCurrentFolderId', folder_id)
    }
  },
}
</script>

<style scoped lang="scss">

</style>
