<template>

<div class="stars">

  <div class="thumbs" v-if="bookmarks.length">
    <div class="item" v-for="b in this.bookmarks" v-bind:key="b.id">
      <a class="thumb" :href="b.url" target="_blank">
        <img :src="b.thumb" @click="visit(b.id)" alt="thumb" :title="b.url"/>
      </a>
      <b-link :to="editurl(b.id)" class="title">{{ b.title }}</b-link>
    </div>
  </div>

  <div class="nostars vertical-space" v-if="!bookmarks.length">
    <h2>{{ t('no_stars') }}</h2>
    <p>{{ t('no_stars_text') }}</p>
  </div>

</div>

</template>

<script>

export default {
  computed: {
    bookmarks: function() {
      var retour = this.$store.state.bookmarks
      retour = retour.filter(function (b) {
        return (b.star == 1)
      })
      retour.sort(function(a,b) {
        return b.score - a.score
      })
      return retour
    },
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    editurl: function(id) {
      return "/edit/"+ id
    },
    visit: function(id) {
      this.$http.get('/bookmarks/visit/'+ id).then(response => {
        this.$store.commit('replaceBookmark', response.data)
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
  },
}
</script>

<style scoped lang="scss">

div.nostars {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
  text-align: center;
  h2 {
    font-size: 400%;
    font-weight: bold;
  }
  p {
    font-size: 150%;
  }
}

div.thumbs {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  // justify-content: center;
  overflow: hidden;
}

div.item {
  flex: 0 0 auto;
  width: 110px;
  margin: 20px;
  overflow: hidden;
  text-align: center;
  a.thumb {
    display: flex;
    flex-direction: column;
    height: 130px;
    justify-content: center;
  }
  a.title {
    display: block;
    height: 2.2em;
    line-height: 1.1em;
  }
}

@media only screen and (max-width: 960px) {

  div.nostars {
    height: 200px;
  }

  div.thumbs {
    justify-content: center;
  }
  div.item {
    width: 100px;
    margin: 15px;
    a.thumb {
      height: 130px;
    }
  }

}
</style>
