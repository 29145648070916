<template>
  <b-container fluid>
    <b-row>
      <b-col lg="7">
        <BookmarkStars/>
      </b-col>
      <b-col>
        <BookmarkList v-bind:isMobile="isMobile"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import BookmarkList from '@/components/BookmarkList.vue'
import BookmarkStars from '@/components/BookmarkStars.vue'

export default {
  components: {
    BookmarkList,
    BookmarkStars,
  },
  computed: {
    isMobile: function() {
      return this.$store.getters.isMobile
    },
  },
}
</script>

<style scoped lang="scss">

</style>
