<template>

  <div class="scroll py-3" :class="{ mobile: isMobile, active: isActive }">
    <h2>{{ t('library') }}</h2>
    <BookmarkTools/>
    <table>
      <tr>
        <th width="40">{{ t('star') }}</th>
        <th width="60"></th>
        <th @click="setCurrentOrder('title+')">{{ t('title') }}</th>
        <th width="50" @click="setCurrentOrder('score-')">{{ t('score') }}</th>
        <th width="80" @click="setCurrentOrder('created-')">{{ t('created_at') }}</th>
        <th width="80" @click="setCurrentOrder('visited-')">{{ t('visited_at') }}</th>
      </tr>
      <tr v-for="b in this.displayedBookmarks" v-bind:key="b.id">
        <td class="star center">
          <input type="checkbox" @click="star(b.id)" :checked="b.star"/>
        </td>
        <td class="thumb">
          <a :href="b.url" target="_blank">
            <img :src="b.thumb" @click="visit(b.id)" alt="thumb" :title="b.url"/>
          </a>
        </td>
        <td class="title">
          <router-link :to="editurl(b.id)" class="title">{{ b.title }}</router-link>
          <span class="url">{{ b.url }}</span>
        </td>
        <td class="center">{{ b.score }}</td>
        <td class="date">{{ b.created_at|date('L') }}</td>
        <td class="date">{{ b.visited_at|date('L') }}</td>
      </tr>
    </table>
    <p class="center">
      <a v-if="this.resultsRemaining" class="more" href="javascript:;" @click="moreresult()">{{ t('more_result') }}</a>
    </p>
  </div>
</template>

<script>

import BookmarkTools from '@/components/BookmarkTools.vue'

export default {
  props: ['isMobile'],
  components: {
    BookmarkTools,
  },
  data: function() {
    return {
      maxresult: 11,
      isActive: true,
    }
  },
  computed: {
    bookmarks: function() {
      var retour = this.$store.state.bookmarks
      retour = this.filterFolder(retour)
      retour = this.filterQuery(retour)
      retour = this.filterOrder(retour)
      return retour
    },
    displayedBookmarks: function() {
      return this.bookmarks.slice(0,this.maxresult)
    },
    leftResults: function() {
      return this.bookmarks.length - this.maxresult
    },
    currentOrder: function() {
      return this.$store.state.currentOrder
    },
    resultsRemaining: function() {
      return this.leftResults > 0
    },
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    togglevisibility: function() {
      this.isActive = !this.isActive
    },
    editurl: function(id) {
      return "/edit/"+ id
    },
    visit: function(id) {
      this.$http.get('/bookmarks/visit/'+ id).then(response => {
        this.$store.commit('replaceBookmark', response.data)
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
    star: function(id) {
      this.$http.get('/bookmarks/star/'+ id).then(response => {
        this.$store.commit('replaceBookmark', response.data)
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
    moreresult: function() {
      this.maxresult += this.maxresult
    },
    setCurrentOrder: function(order) {
      if (order == "score-" && this.$store.state.currentOrder == "score-") {
        this.$store.commit('setCurrentOrder', "score+")
      } else if (order == "visited-" && this.$store.state.currentOrder == "visited-") {
        this.$store.commit('setCurrentOrder', "visited+")
      } else if (order == "created-" && this.$store.state.currentOrder == "created-") {
        this.$store.commit('setCurrentOrder', "created+")
      } else if (order == "title+" && this.$store.state.currentOrder == "title+") {
        this.$store.commit('setCurrentOrder', "title-")
      } else {
        this.$store.commit('setCurrentOrder', order)
      }
    },
    filterFolder: function(bookmarks) {
      var currentFolderId = this.$store.state.currentFolderId
      if (currentFolderId !== undefined) {
        currentFolderId = (currentFolderId > 0) ? currentFolderId : null
        bookmarks = bookmarks.filter(function (b) {
          return b.folder_id == currentFolderId
        })
      }
      return bookmarks
    },
    filterQuery: function(bookmarks) {
      var query = this.$store.state.query
      bookmarks = bookmarks.filter(function (b) {
        var searchRegex = new RegExp(query, 'i')
        return (
          searchRegex.test(b.title) ||
          searchRegex.test(b.url)
        )
      })
      return bookmarks
    },
    filterOrder: function(bookmarks) {
      var currentOrder = this.$store.state.currentOrder
      bookmarks.sort(function(a,b) {
        switch(currentOrder) {
          case 'score-': return b.score - a.score
          case 'score+': return a.score - b.score
          case 'visited-': return new Date(b.visited_at) - new Date(a.visited_at)
          case 'visited+': return new Date(a.visited_at) - new Date(b.visited_at)
          case 'created-': return new Date(b.created_at) - new Date(a.created_at)
          case 'created+': return new Date(a.created_at) - new Date(b.created_at)
          case 'title-': return b.title.localeCompare(a.title)
          case 'title+': return a.title.localeCompare(b.title)
        }
      })
      return bookmarks
    }
  },
}
</script>

<style scoped lang="scss">

div.scroll {
  position: relative;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  background-color: #e8e8e8;
}
h2 {
  padding: .4em;
  text-transform: capitalize;
  font-family: 'allerregular';
  text-align: center;
  color: #666;
  font-size: 1.5em;
}
table {
  width: 100%;
  font-size: .9em;
  border-collapse: collapse;
  table-layout: fixed;
}
tr:nth-child(odd) {
  background: rgba(200,200,200,.3);
}
td, th {
  padding: .2em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
th {
  background-color: #ccc;
  cursor: pointer;
  padding: 1em .2em;
  text-align: center;
  opacity: .8;
}
th:hover {
  opacity: 1;
}
.more {
  display: inline-block;
  margin: 0em;
  padding: 1em;
  text-transform: uppercase;
  font-size: .9em;
  border-radius: 1em;
}
div.scroll.mobile {
  height: auto;
}
span.url {
  display: block;
  font-style: italic;
  color: #666;
}

</style>
